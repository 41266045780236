import { SVGProps } from "react";

export default function Chat1(props: SVGProps<SVGSVGElement>) {
  const isDark = props?.type === "dark";
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.669922" y="0.332031" width="32" height="32" rx="16" fill={isDark ? "white" : "#5C1FC4"} />
      <path
        d="M20.5664 22.3327C20.3138 22.3329 20.0656 22.2671 19.8468 22.142C19.628 22.0169 19.4464 21.8369 19.3203 21.6201L14.758 13.7934C14.5706 13.4665 14.5213 13.0795 14.6209 12.7167C14.7204 12.354 14.9607 12.0449 15.2893 11.8569C15.6179 11.6689 16.0083 11.6172 16.3752 11.7131C16.7422 11.809 17.056 12.0447 17.2482 12.3688L21.8105 20.1956C21.9365 20.412 22.0029 20.6575 22.0029 20.9074C22.003 21.1572 21.9367 21.4027 21.8107 21.6192C21.6847 21.8357 21.5034 22.0155 21.2851 22.1407C21.0667 22.2659 20.819 22.332 20.5667 22.3324L20.5664 22.3327ZM15.2841 22.3324H11.441C11.0596 22.3324 10.6938 22.1823 10.4241 21.9152C10.1544 21.6481 10.0029 21.2858 10.0029 20.908C10.0029 20.5302 10.1544 20.1679 10.4241 19.9008C10.6938 19.6337 11.0596 19.4836 11.441 19.4836H15.2841C15.6655 19.4836 16.0312 19.6337 16.3009 19.9008C16.5705 20.1679 16.722 20.5301 16.722 20.9078C16.722 21.2856 16.5705 21.6478 16.3009 21.9149C16.0312 22.182 15.6655 22.3321 15.2841 22.3321V22.3324Z"
        stroke={isDark ? "#5C1FC4" : "white"}
        strokeMiterlimit="10"
      />
      <path
        d="M11.4175 22.3327C11.1691 22.3323 10.9251 22.2664 10.71 22.1416C10.495 22.0167 10.3165 21.8373 10.1923 21.6213C10.0682 21.4053 10.0029 21.1603 10.0029 20.911C10.0029 20.6617 10.0683 20.4168 10.1925 20.2008L14.6852 12.3921C14.7768 12.228 14.9001 12.0838 15.0477 11.9679C15.1953 11.852 15.3644 11.7666 15.5452 11.7168C15.726 11.6669 15.9148 11.6536 16.1007 11.6776C16.2867 11.7016 16.466 11.7624 16.6283 11.8564C16.7907 11.9505 16.9328 12.076 17.0464 12.2256C17.1601 12.3752 17.243 12.546 17.2903 12.728C17.3377 12.91 17.3486 13.0997 17.3223 13.2859C17.2961 13.4722 17.2332 13.6514 17.1374 13.8131L12.645 21.6218C12.5207 21.8381 12.3418 22.0178 12.1262 22.1426C11.9106 22.2674 11.6661 22.333 11.4172 22.3327H11.4175Z"
        stroke={isDark ? "#5C1FC4" : "white"}
        strokeMiterlimit="10"
      />
    </svg>
  );
};
