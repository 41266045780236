import { SVGProps } from "react";
export default function Chat3(props: SVGProps<SVGSVGElement>) {
  const isDark = props?.type === "dark";
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.669922"
        y="0.332031"
        width="32"
        height="32"
        rx="16"
        fill={isDark ? "white" : "#5C1FC4"}
      />
      <path
        d="M16.3417 13.4658L16.582 13.4476L16.8108 13.466C17.7219 13.5391 18.3465 14.0121 18.5046 14.6995H18.1187C18.0505 14.4602 17.9234 14.2564 17.7452 14.0966C17.5099 13.8855 17.2034 13.7665 16.869 13.7248L16.6058 13.692L16.6058 13.6847L16.5767 13.6883L16.5479 13.6848V13.692L16.2848 13.7248C15.5401 13.8178 15.0347 14.2757 15.0347 14.8809C15.0347 15.1597 15.1308 15.4164 15.3514 15.6251C15.5611 15.8235 15.8649 15.9611 16.2511 16.0578L16.6062 16.1467L16.6062 16.1411L16.7646 16.1804L16.9829 16.2347L16.9829 16.2348L16.9873 16.2358C17.5668 16.3703 17.9745 16.5485 18.2349 16.7801C18.4818 16.9997 18.6156 17.2842 18.6156 17.6935C18.6156 18.1633 18.4278 18.5193 18.1201 18.7734C17.8053 19.0334 17.3501 19.1973 16.8134 19.2353L16.57 19.2525L16.3415 19.2354C15.8091 19.1955 15.3524 19.0268 15.0311 18.7627C14.7863 18.5614 14.6138 18.3004 14.5424 17.9792H14.9346C15.0039 18.2022 15.1226 18.4065 15.3033 18.5736C15.5469 18.7989 15.879 18.9341 16.2901 18.9776L16.5479 19.0049V19.006L16.5542 19.0056L16.6059 19.011V19.0016L16.8553 18.9826C17.2816 18.9502 17.6263 18.8303 17.8677 18.6141C18.116 18.3919 18.2236 18.0962 18.2236 17.7843C18.2236 17.4894 18.1391 17.2184 17.8964 16.9984C17.6726 16.7955 17.3393 16.6602 16.9019 16.5526L16.5479 16.4655V16.4734L16.3883 16.4343L16.202 16.3886L16.202 16.3885L16.1984 16.3877C15.6441 16.2592 15.255 16.0781 15.0071 15.848C14.77 15.628 14.643 15.3459 14.643 14.9578C14.643 14.5321 14.8112 14.1872 15.0982 13.9344C15.3897 13.6776 15.8173 13.5054 16.3417 13.4658ZM16.6699 9.76032C20.2992 9.76032 23.2413 12.7024 23.2413 16.3318C23.2413 19.9611 20.2992 22.9032 16.6699 22.9032C13.0405 22.9032 10.0985 19.9611 10.0985 16.3318C10.0985 12.7024 13.0405 9.76032 16.6699 9.76032ZM16.6699 10.3318C13.3561 10.3318 10.6699 13.018 10.6699 16.3318C10.6699 19.6455 13.3561 22.3318 16.6699 22.3318C19.9837 22.3318 22.6699 19.6455 22.6699 16.3318C22.6699 13.018 19.9837 10.3318 16.6699 10.3318Z"
        fill={isDark ? "#5C1FC4" : "white"}
        stroke={isDark ? "#5C1FC4" : "white"}
        strokeWidth="0.571429"
      />
    </svg>
  );
}
